import Mainpost2 from './mainpost2';
import Nav from './navbar';
import Search1 from './search1';
import { useEffect } from 'react';
import { useState } from 'react';
import React from 'react';
import axios from 'axios';
import Category from './category';
import { Spin, message } from 'antd';
import { useParams } from 'react-router-dom';
import { Carousel } from 'antd';
import { API_URL, footer_menu_class, uploads } from '../config/config';
import { Modal } from 'antd';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import Footer_menu from './footer_menu';
import Catalog from './catalog';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const items = [
  getItem('Navigation One', 'sub1', <MailOutlined />, [
    getItem('Item 1', null, null, [getItem('Option 1', '1'), getItem('Option 2', '2')], 'group'),
    getItem('Item 2', null, null, [getItem('Option 3', '3'), getItem('Option 4', '4')], 'group'),
  ]),
  getItem('Navigation Two', 'sub2', <AppstoreOutlined />, [
    getItem('Option 5', '5'),
    getItem('Option 6', '6'),
    getItem('Submenu', 'sub3', null, [getItem('Option 7', '7'), getItem('Option 8', '8')]),
  ]),
  getItem('Navigation Three', 'sub4', <SettingOutlined />, [
    getItem('Option 9', '9'),
    getItem('Option 10', '10'),
    getItem('Option 11', '11'),
    getItem('Option 12', '12'),
  ]),
  getItem('Navigation for', 'sub3', <MailOutlined />, [
    getItem('Item 3', null, null, [getItem('Option 5', '5'), getItem('Option 4', '4')], 'group'),
    getItem('Item 4', null, null, [getItem('Option 6', '4'), getItem('Option 5', '4')], 'group'),
  ]),
  getItem('Navigation five', 'su51', <MailOutlined />, [
    getItem('Item 5', null, null, [getItem('Option 7', '7'), getItem('Option 2', '2')], 'group'),
    getItem('Item 6', null, null, [getItem('Option 8', '8'), getItem('Option 4', '4')], 'group'),
  ]),
];
const onClick = (e) => {
  
};

const Main =()=>{
  

  const [messageApi, contextHolder] = message.useMessage();
  // const user_id = localStorage.getItem('token');
 
  const [id_admin,setId_admin] = useState(3);
  const [products,setProducts] = useState(null);
  const [product_cat,setProduct_cat] = useState(null);
  const [message1, setMessage1] = useState(null);
  const [admin_get, setAdmin_get] = useState(null);
  const [usertoken, setUsertoken] = useState(null);

  const useridtoken = async () => {
    if(localStorage.getItem('token') != null){
        const post = {
            id: localStorage.getItem('token'),
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/users5'
        });
        
        if (data.data.status == 200) {
          localStorage.setItem('text','');
            
            setUsertoken(data.data.users5[0].id);
           
          user_status(data.data.users5[0].id);
          
        }if(localStorage.getItem('g') != null){
            const post = {
                id: localStorage.getItem('g'),
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL + '/users5'
            });
            
            if (data.data.status == 200) {
                
                setUsertoken(data.data.users5[0].id);
                
          user_status(data.data.users5[0].id);
            }
    }
        } 
  }
 
    const fetchProducts = async () => {
        const data = await axios({
            method:'get',
            url:API_URL+'/category'
        });
        if(data.data.status == 200){
            setProducts(data.data.post);
            
        }else{
            
        }
    }
    const fetchProduct_cat = async () => {
      const params = {
        id: 2,
      };
      const data = await axios({
          params: params,
          method:'get',
          url:API_URL+'/getcategorytype'
      });
      if(data.data.status == 200){
          setProduct_cat(data.data.getcategorytype);
          
      }else{
          
      }
  }
    const [banner,setBanner] = useState(null);
    const fetchBanner = async () => {
        const data = await axios({
            method:'get',
            url:API_URL+'/reklama'
        });
        if(data.data.status == 200){
            setBanner(data.data.reklama);
            
        }else{
            
        }
    }
    const params = useParams();
    const id = params.id;
    const [products_podcat,setProducts_podcat] = useState(null);
    const fetchProducts_podcat= async () => {
        const post = {
            id: id,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL+'/postcategory'
        });
        if (data.data.status == 200) {
            setProducts_podcat(data.data.postcategory);
            
        } else {
            
            
        }
    }
    const fetchAdmin= async () => {
      const post = {
          id: id_admin,
      };
      const data = await axios({
          method: 'get',
          params: post,
          url: API_URL+'/id_admin'
      });
      if (data.data.status == 200) {
          setAdmin_get(data.data.id_admin);
          // 
      } else {
          
          
      }
  }



const text = <span>Подкатегории</span>;
const content = (
  
  <div style={{width:'1000px',}}>
   {products_podcat != null || products_podcat != undefined || products_podcat?.length > 0 ?
    <>
                    {products.map((products_podcat)=>
            
                    <div className="col-md-2">
                    <a href={"/podcategory/"+ products_podcat.id}> <img  style={{width:'80px',height:'80px',borderRadius:'50%'}} src={products_podcat.image}></img></a>
                    <p style={{color:'black',fontSize:'18px',fontFamily:'initial'}}>{products_podcat.name}</p>
                    </div>
                    )
                    }
    </>
    :<>Loading</>
}
  </div>
);
const buttonWidth = '100%';
const [container, setContainer] = useState(null);
const [isModalOpen, setIsModalOpen] = useState(false);
const [isModalOpen2, setIsModalOpen2] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const showModal2 = () => {
    setIsModalOpen2(true);
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleOk2 = () => {
    chat_if();
    setIsModalOpen2(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const chat_if = async () => {
    if (usertoken  != null) {
        const params = {
            ad_id:0,
            user1_id: usertoken ,
            user2_id: 0,
        };
        const data = await axios({
            method: 'get',
            params: params,
            url: API_URL + '/chat_if'
        });
        
        if (data.data.status == 200){
            chat(data.data.chat_if[0].id);
            
        } else {
            error();
            
            chats();
            // window.location.href="/mainsms/";
            
        }
    } else {
       warning();
    }
   

}
const chats = async ()=>{
  const params = {
      ad_id:0,
      user1_id:usertoken ,
      user2_id:0,
  };
  const data = await axios({
      method:'post',
      params:params,
      url:API_URL+'/chats'
  });
  
  if(data != null){
      if(data.status  == 200){
      
      // fetchProducts();
      localStorage.setItem('chat',message1);
      setTimeout(chat_if,2000);
      // window.location.href="/mainsms/"+product[0].id;
      }else{
          alert('Error')
      }
  }
}
const chat = async (room_id) => {
  const params = {
      ad_id: 0,
      user_id: usertoken ,
      message: message1,
      room_id: room_id,
      user_id2: 0
  };
  const data = await axios({
      method: 'post',
      params: params,
      url: API_URL + '/chat'
  });
  
  if (data != null){
      if (data.status == 200){
          success();
      } else {
          alert('Error')
      }
  }
}


const success = () => {
  messageApi.open({
    type: 'success',
    content: 'Сообщение отправлено!',
  });
};
const success2 = () => {
  messageApi.open({
    type: 'success',
    content: 'Успешно!',
  });
};

const error = () => {
  messageApi.open({
    content:  <Spin /> ,
  });
};

const warning = () => {
  messageApi.open({
    type: 'warning',
    content: 'Авторизуйтесь!',
  });
}

const user_status = async (usertoken)=>{
  const params = {
      user1_id:usertoken
  };
  const data = await axios({
      method:'post',
      params:params,
      url:API_URL+'/user_status'
  });
  
  if(data != null){
      if(data.data.status  == 200){
      
      }else{
          user_status_update(usertoken);
      }
  }
}
const user_status_update = async (usertoken)=>{
  const params = {
      user1_id:usertoken
  };
  const data = await axios({
      method:'put',
      params:params,
      url:API_URL+'/user_status'
  });
  
  if(data != null){
      if(data.data.status  == 200){
      
      }else{
          // alert('Error')
      }
  }
}



useEffect(() => {
  fetchProduct_cat();
  useridtoken();
  fetchAdmin();
  fetchProducts();
  fetchProducts_podcat();
  fetchBanner();

  const script = document.createElement('script');
  script.src = 'https://yandex.ru/rtb/js/async.js';
  script.async = true;
  document.body.appendChild(script);

  // После загрузки скрипта рендерим рекламу
  script.onload = () => {
    window.yaContextCb.push(() => {
      window.Ya.Context.AdvManager.render({
        blockId: "R-A-14191515-1",  // Это твой blockId
        renderTo: "yandex_rtb_R-A-14191515-1",  // Это id контейнера
      });
    });
  };

  // Очистка после удаления компонента
  return () => {
    document.body.removeChild(script);
  };

}, []);
    return(
      
<>
<div class="modal fade"   data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="staticBackdropLabel">Москвагы Кыргыздардын жарнамалары</h1>
        <button type="button" class="close"  aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div style={{overflow:'scroll',height:'350px',fontSize:'8px'}} class=" modal-dialog-scrollable">
        <a href='birimdik.kg' id='main' title='Биримдик кж'><h6>
          Комната издейм
        </h6></a>
        <a  href='birimdik.kg'  id='main' title='Биримдик.кж'><h6>
          Квартира издейм
        </h6></a>
        <a  href='birimdik.kg'  id='main' title='Бирге.ру'><h6>
          Койка место
        </h6></a>
        <a  href='birimdik.kg'  id='main' title='Жердеш.ру'><h6>
          Сниму квартиру
        </h6></a>
        <a  href='birimdik.kg'  id='main' title='Birimdik.ru'><h6>
          Сдаю квртиру
        </h6></a>
        <a  href='birimdik.kg'  id='main' title='Birge.ru'><h6>
          Таксопарк
        </h6></a>
        <a  href='birimdik.kg'  id='main' title='Jerdesh.ru'><h6>
          Аренда авто
        </h6></a>
        <a  href='birimdik.kg'  id='main' title='Yntymak.ru'><h6>
          Москва жарнама
        </h6></a>
        <a  href='birimdik.kg' id='main' title='jal-jal.ru'><h6>
          Жердеш.ру
        </h6></a>
        <a  href='birimdik.kg'  id='main' title='Moskva jarnama'><h6>
          Бирге.ру
        </h6></a>
        <a  href='birimdik.kg'  id='main' title='Квартира издейм жердеш'><h6>
          Ынтымак.ру
        </h6></a>
        <a  href='birimdik.kg' id='main' title='Комната издейм жердеш'><h6>
          Биримдик.кж
        </h6></a>
        <a  href='birimdik.kg'  id='main' title='Жумуш издейм Метро'><h6>
          Биримдик.ру
        </h6></a>
        <a  href='birimdik.kg'  id='main' title='Квартира Метро'><h6>
          Москва квартира
        </h6></a>
        <a  href='birimdik.kg' id='main' title='Койка место метро'><h6>
          Москва койка место
        </h6></a>
        <a  href='birimdik.kg' id='main' title='Kyrgyz Moskva'><h6>
          Москва Кыргыздардын жарнамалары
        </h6></a>
        <a  href='birimdik.kg'  id='main' title='Москва жарнама'><h6>
          Жумуш издейм
        </h6></a>
        <a  href='birimdik.kg' id='main' title='Жердеш жарнама'><h6>
          Жумуш берилет
        </h6></a>
        <a href='birimdik.kg'  id='main' title='Жаны сайт Москва'><h6>
          Москвадагы Кыргыз врач
        </h6></a>
        <a  href='birimdik.kg'   id='main'  title='Москавда кырдыздардын'><h6>
          Квартира метро
        </h6></a>
      </div>
      <div class="modal-footer">
        {/* <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> */}
        {/* <a href='/registr'><button type="button" class="btn btn-primary" >Принмаю соглашение обработке данных</button></a> */}
      </div>
    </div>
  </div>
</div>

{<div style={{zIndex:'5'}}>{contextHolder}</div>}
      <Modal  title="Отправить сообщение" open={isModalOpen2} onOk={handleOk2} cancelText="Отмена" onCancel={handleCancel2}>
        <textarea onChange={(e) => { setMessage1(e.target.value) }} style={{height:'100px'}} className="form-control"></textarea>
      </Modal>
      <Modal title="Связаться с оператором" open={isModalOpen} onOk={handleOk} okText='OK' cancelText='Отмена' onCancel={handleCancel}>
        <a href={'https://wa.me/'+79936017975}><p  style={{fontSize:'22px'}}><i class="fa-brands fa-whatsapp"> Написать на Ватсап</i></p></a>
        <a href={'https://t.me/'+'Birimdik_kg_site'}><p  style={{fontSize:'22px'}}><i class="fa-brands fa-telegram"></i> Написать на Телеграм</p></a>
        <a onClick={showModal2}><p style={{fontSize:'22px'}}><i class="fa-solid fa-headset"></i> Оставить сообщение на сайте</p></a>
        <a href='https://birimdik.kg/app/birimdik.apk'><p style={{fontSize:'22px'}}><i class="fa-brands fa-android"></i> Скачать приложение</p></a>
      </Modal>
<button  onClick={showModal} className='btn btn-light d-none d-md-block' style={{fontSize:'32px',borderRadius:'50%',position:'relative', position:'fixed',zIndex:'4',color:'deepskyblue'}}><i class="fa-solid fa-headset"></i></button>
<button  onClick={showModal} className='btn btn-light d-block d-md-none' style={{fontSize:'32px',borderRadius:'50%',position:'relative', position:'fixed',zIndex:'4',color:'deepskyblue',bottom:'80px',right:'20px'}}><i class="fa-solid fa-headset"></i></button>


   
 
{banner != null ? <> 
  <div className="container-fluid p-0">
      <div className="row g-0">
        {/* Левая колонка */}
        <div 
          className="col-2 d-none d-md-block" 
          style={{
            backgroundColor: '#f8f9fa', 
            minHeight: '100vh'
          }}
        >
           <div id="yandex_rtb_R-A-14191515-1"></div>

          {/* {banner != null ? <>
          {banner.filter(banner => banner.prioritet == 'L1').map(filteredBanner => (
            <a onClick={showModal}>{filteredBanner.image != null ? <><a href='https://tp.media/r?marker=605155&trs=387608&p=4114&u=https%3A%2F%2Faviasales.com&campaign_id=100'><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads + filteredBanner.image}></img></a></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L2').map(filteredBanner => (
            <a onClick={showModal}>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L3').map(filteredBanner => (
            <a onClick={showModal}>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L4').map(filteredBanner => (
            <a onClick={showModal}>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L5').map(filteredBanner => (
            <a onClick={showModal}>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
        ))}
        </> : <></>}
        {banner != null ? <>
          {banner.filter(banner => banner.prioritet == 'L1').map(filteredBanner => (
            <a onClick={showModal}>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L2').map(filteredBanner => (
            <a onClick={showModal}>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L3').map(filteredBanner => (
            <a onClick={showModal}>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L4').map(filteredBanner => (
            <a onClick={showModal}>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'L5').map(filteredBanner => (
            <a onClick={showModal}>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
        ))}
        </> : <></>} */}
        </div>

        {/* Мобильная версия левой колонки */}
        <div 
          className="col-12 d-md-none" 
          style={{
            backgroundColor: '#f8f9fa', 
            minHeight: '50px'
          }}
        >
       
        </div>

        {/* Центральная колонка */}
        <div 
          className="col-12 col-md-8 " 
          style={{
            backgroundColor: 'white',
            minHeight: '100vh',
            paddingLeft:'10px'
          }}
        >
          
           <div  className='bg-light  '>      
                    <Nav/>
                    <Search1/> 
                  <div className='col-12 text-center '>
              </div>
              
                  {products != null || products != undefined || products?.length > 0 ?
      <>
  <div  className='col-12 text-center '>
                <h2  style={{fontFamily:'sans-serif'}}>Категории</h2>
              </div>
              
                  
                  <div  className='row '>
                  {products != null || products != undefined || products?.length > 0 ? (
  <>
    <div className="bg-white shadow-lg rounded-lg overflow-hidden uk-card uk-card-default" style={{ marginLeft: '0px' }}>
      <nav className="nav-scroller__items navscroll d-block d-md-none" style={{ height: '100%', width: '100%' }}>
        <div 
          className="nav-scroller" 
          style={{
            background: 'transparent',
            overflowX: 'auto',
            WebkitOverflowScrolling: 'touch',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none'
          }}
        >
          <div 
            className="flex space-x-4 p-4"
            style={{
              minWidth: 'min-content',
              scrollBehavior: 'smooth'
            }}
          >
            {products.map((item, index) => (
              <div 
                key={index} 
                className="transition-all duration-300 transform hover:scale-105"
                style={{
                  flex: '0 0 auto',
                  width: '80px'
                }}
              >
                <a 
                  href={`/${item.link}`}
                  className="flex flex-col items-center group"
                >
                  <div className="relative mb-2">
                    <div className="absolute inset-0 bg-blue-500 opacity-0 group-hover:opacity-10 transition-opacity duration-300 rounded-lg"></div>
                    <img
                      src={uploads + item.image}
                      alt={item.name}
                      className="w-16 h-16 rounded-lg object-cover shadow-md transition-transform duration-300 group-hover:shadow-lg"
                      style={{ marginLeft: '15px' }}
                    />
                  </div>
                  <p className="text-sm text-center font-medium text-gray-700 group-hover:text-blue-600 transition-colors duration-300" 
                     style={{ fontSize: '10px', fontFamily: 'initial' }}>
                    {item.name}
                  </p>
                </a>
              </div>
            ))}
          </div>
        </div>
      </nav>
    </div>
  </>
) : (
  <div className="flex items-center justify-center p-4">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
  </div>
)}

 {product_cat != null || product_cat != undefined || product_cat?.length > 0 ? (
  <>
    <div className="bg-white shadow-lg rounded-lg overflow-hidden uk-card uk-card-default " style={{marginLeft: '0px'}} >
      <nav className="nav-scroller__items navscroll d-block d-md-none" style={{height: '100%', width: '100%'}}>
        <div 
          className="nav-scroller" 
          style={{
            background: 'transparent',
            overflowX: 'auto',
            WebkitOverflowScrolling: 'touch',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none'
          }}
        >
          <div 
            className="flex space-x-4 p-4"
            style={{
              minWidth: 'min-content',
              scrollBehavior: 'smooth'
            }}
          >
            {product_cat.map((item, index) => (
              <div 
                key={index} 
                className="transition-all duration-300 transform hover:scale-105"
                style={{
                  flex: '0 0 auto',
                  width: '80px',
                  top:'0px'
                }}
              >
                <a 
                  href={`/${item.link}`}
                  className="flex flex-col items-center group"
                >
                  <div className="relative mb-2">
                    <div className="absolute inset-0 bg-blue-500 opacity-0 group-hover:opacity-10 transition-opacity duration-300 rounded-lg"></div>
                    <img
                      src={uploads + item.image}
                      alt={item.name}
                      className="w-16 h-16 rounded-lg object-cover shadow-md transition-transform duration-300 group-hover:shadow-lg"
                      style={{marginLeft: '15px'}}
                    />
                  </div>
                  <p className="text-sm text-center font-medium text-gray-700 group-hover:text-blue-600 transition-colors duration-300" 
                     style={{fontSize: '10px', fontFamily: 'initial'}}>
                    {item.name}
                  </p>
                </a>
              </div>
            ))}
          </div>
        </div>
      </nav>
    </div>
  </>
) : (
  <div className="flex items-center justify-center p-4">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
  </div>
)}
      
      </div>
          <div  className='row '>
           

              {products != null || products != undefined || products?.length > 0 ?
      <>
      {products.map((item)=>
          <div style={{zIndex:'2'}} className='col-auto ml-5 text-center d-none d-md-block '>
      

        <Category products={item}/>
          
          </div>
      )
      }
      </>
      :<>Loading</>
  }
  {product_cat != null || product_cat != undefined || product_cat ?.length > 0 ?
      <>
      {product_cat.map((item)=>
          <div style={{zIndex:'2'}} className='col-auto ml-5 text-center d-none d-md-block '>
          
        <Category products={item}/>
          
          </div>
      )
      }
      </>
      :<>Loading</>
  }

  
         
        </div>
        </>
      :<>Loading</>
  }
  
  
  <div className='carousel mt-3'>
    {banner != null ? <>
      <Carousel autoplay  >
      <div>
        <a onClick={showModal}>
          <h3 className='carousel2'  >
        {banner.filter(banner => banner.prioritet == 'C1').map(filteredBanner => ( 
                      <a >{filteredBanner.image != null ? <><img  className='carimg' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
                  ))}
        </h3></a>
      </div>
      <div>
      {banner.filter(banner => banner.prioritet == 'C2').map(filteredBanner => ( 
          <a onClick={showModal}>{filteredBanner.image != null ? <><img className='carimg' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
      ))}
      </div>
      <div>
      {banner.filter(banner => banner.prioritet == 'C3').map(filteredBanner => ( 
          <a onClick={showModal}>{filteredBanner.image != null ? <><img  className='carimg' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
      ))}
      </div>
      <div>
      {banner.filter(banner => banner.prioritet == 'C4').map(filteredBanner => ( 
          <a onClick={showModal}>{filteredBanner.image != null ? <><img   className='carimg' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
      ))}
      </div>
    </Carousel>
    </> : <></>}
            
            </div> 
             <div style={{marginTop:'30%'}} className=''>
           
                <Mainpost2/>
            </div>     
        
              <div  className=' mt-2'>
                  <Catalog/>
                  </div>
                  </div>
        </div>

        {/* Правая колонка */}
        <div 
          className="col-2 d-none d-md-block p-3" 
          style={{
            backgroundColor: '#f8f9fa', 
            minHeight: '100vh'
          }}
        >
           <div id="yandex_rtb_R-A-14191515-1"></div>
          {/* {banner != null ? <>
            {banner.filter(banner => banner.prioritet == 'R1').map(filteredBanner => (
            <a onClick={showModal}>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R2').map(filteredBanner => (
            <a onClick={showModal}>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R3').map(filteredBanner => (
            <a onClick={showModal}>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R4').map(filteredBanner => (
            <a onClick={showModal}>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R5').map(filteredBanner => (
            <a onClick={showModal}>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
        ))}
        </> : <></>}
        {banner != null ? <>
            {banner.filter(banner => banner.prioritet == 'R1').map(filteredBanner => (
            <a onClick={showModal}>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R2').map(filteredBanner => (
            <a onClick={showModal}>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R3').map(filteredBanner => (
            <a onClick={showModal}>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R4').map(filteredBanner => (
            <a onClick={showModal}>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
        ))}
        {banner.filter(banner => banner.prioritet == 'R5').map(filteredBanner => (
            <a onClick={showModal}>{filteredBanner.image != null ? <><img style={{marginTop:'50px'}} width='100%' height='280px' src={uploads +filteredBanner.image}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads+'no_image3.png'}></img></>}</a>
        ))}
        </> : <></>} */}
        </div>

        {/* Мобильная версия правой колонки */}
        <div 
          className="col-12 d-md-none" 
          style={{
            backgroundColor: '#f8f9fa', 
            minHeight: '50px'
          }}
        >
       
        </div>
      </div>
    </div>

      <div  className={footer_menu_class}>
      <Footer_menu/>
    </div>  
    <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Согласие обработка данных</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div style={{overflow:'scroll',height:'350px',fontSize:'8px'}} class=" modal-dialog-scrollable">
  
"Оставляя на сайте, размещенном по веб-адресу: Birimdik.ru , Birimdik.kg (далее – Сайт), принадлежащем ИНН 770379123213, оператор персональных данных рег.№770379123213 (далее - Администрация Сайта), любые из Ваших данных (которые отнесены к категории персональных в соответствии с ФЗ «О персональных данных» от 27.07.2006 №152-ФЗ), путем заполнения полей любых форм на Сайте, Вы автоматически присоединяетесь к настоящему Соглашению, приобретаете статус пользователя Сайта (далее-Пользователь Сайта) и соглашаетесь со всеми без исключения его условиями.

Пользователь Сайта:

1. Подтверждает, что все указанные им в формах регистрации на Сайте данные принадлежат лично ему, соответствуют действительности, не искажены.

2. Подтверждает и признает, что им внимательно в полном объеме прочитано настоящее Соглашение и условия обработки его персональных данных. Текст Соглашения и условия обработки персональных данных ему понятны и он с ними согласен;

3. Дает согласие на обработку Администрацией Сайта предоставляемых в составе информации персональных данных в целях заключения между ним и Сайтом настоящего Соглашения, а также его последующего исполнения;

4. Выражает согласие с условиями обработки персональных данных без оговорок и ограничений.

5. В соответствии с ФЗ «О персональных данных» от 27.07.2006 №152-ФЗ свободно, своей волей и в своем интересе выражает согласие на сбор, анализ, обработку, хранение и использование своих персональных данных (ФИО, дата рождения, мобильный телефон, электронный адрес (E-mail), прочие данные).

Согласие Пользователя Сайта на обработку персональных данных является конкретным, информированным и сознательным.

Настоящее согласие Пользователя Сайта признается исполненным в простой письменной форме.

Пользователь Сайта предоставляет Администрации Сайта право осуществлять следующие действия (операции) с персональными данными:

1. сбор и накопление;

2. хранение в течение установленных нормативными документами сроков хранения отчетности, но не менее трех лет, с момента даты прекращения пользования услуг Пользователем Сайта;

3. уточнение (обновление, изменение);

4. использование;

5. уничтожение;

6. обезличивание;

7. передача по требованию суда, в том числе, третьим лицам, с соблюдением мер, обеспечивающих защиту персональных данных от несанкционированного доступа.

Указанное согласие действует бессрочно с момента предоставления данных и может быть отозвано Пользователем Сайта путем подачи заявления Администрации Сайта с указанием данных, определенных ст. 14 Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных» (ред. от 22.02.2017г.).

Отзыв согласия на обработку персональных данных может быть осуществлен путем направления Пользователем соответствующего распоряжения в простой письменной форме на адрес электронной почты birimdik.site@gmail.com.

Администрация Сайта не несет ответственности за использование (как правомерное, так и неправомерное) третьими лицами Информации, размещенной Пользователем Сайта на Сайте, включая её воспроизведение и распространение, осуществленные всеми возможными способами.

Администрация Сайта в любое время имеет право вносить изменения в настоящее Соглашение.

При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Соглашения вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Соглашения.

К настоящему Соглашению и отношениям между Пользователем Сайта и Администрацией Сайта, возникающим в связи с применением Соглашения, подлежит применению действующее законодательство Российской Федерации."
</div>
      <div class="modal-footer">
       <a href='/registr'><button type="button" class="btn btn-primary" >Принмаю соглашение обработке данных</button></a>
      </div>
    </div>
  </div>
</div>
    </> : <></>}


  
</>
    );
    }
    
    
    export default Main;
    



//     <body style={{marginLeft:'10px'}} className='body '>
//     <div  className='row '>
      
//     <div className='rek_left' style={{width:'15%',marginLeft:'-50px'}}>
//       <img  style={{marginTop:'200px'}} width='100%' height='280px' src='https://cbu01.alicdn.com/img/ibank/O1CN01Ysxu2L1oT6HQK0ck0_!!969815225-0-cib.310x310.jpg_undefinedxundefinedundefined.jpg_.webp?_=2020'></img>
//     </div>

//         <div  className='maincon col-md-9 col-12'>
          
//         <div className='bg-light mt-5 row'>

//                 <div className='col-md-12 col-12 '>
//                 <Nav/>
//                 <Search1/>
//                 </div>
                
//                 {products != null || products != undefined || products?.length > 0 ?
//     <>
// <div className='col-12 text-center categorymain'>
//               <h2  style={{fontFamily:'sans-serif'}}>Категории</h2>
//             </div>
//                 <div className='container podcat '>
//         <div  className='row '>
//             {products != null || products != undefined || products?.length > 0 ?
//     <>
//     {products.map((item)=>
//         <div className='col-auto ml-5 text-center d-none d-md-block'>
//           <Popover   placement="bottom" title={text} content={content} trigger="hover">
//           <Button style={{display:'none'}}></Button>
//           <Category products={item}/>
          
//       </Popover>
        
//         </div>
//     )
//     }
//     </>
//     :<>Loading</>
// }
          
//         </div>
//       </div>
//       </>
//     :<>Loading</>
// }
// {products != null || products != undefined || products?.length > 0 ?
//     <>
//     <div style={{marginLeft:'0px'}} class="uk-card uk-card-default  uk-position-z-index" uk-sticky="end: ; offset: 10px">
//   <nav style={{height:'100%',width:'100%'}} class="nav-scroller__items  navscroll d-block d-md-none">
//          <div style={{background:'transparent'}} class="nav-scroller ">
//          <a class="nav-scroller__item" style={{width:'100%'}} href="#">
//          {products.map((item)=>
               
//                <div >
//                    <Category2 products={item}/>
//                  {/* <img style={{width:'40px',height:'40px',borderRadius:'50%'}} src='https://cdn.icon-icons.com/icons2/1151/PNG/512/1486505259-estate-home-house-building-property-real_81428.png'></img> */}
//                </div>
             
             
//          )
//          }
//          </a>
//          </div>
//           </nav>
//     </div>

    
//     </>
//     :<>Loading</>
// }

// <div className='carousel'>
//           <Carousel autoplay  >
//     <div>
//       <h3 className='carousel2'  ><img width='100%' className='carimg'  src='https://images.wbstatic.net/bners1/big_ng_0101.jpg'></img></h3>
//     </div>
//     <div>
    
//       <h3 className='carousel2'><img width='100%' className='carimg'  src='https://images.wbstatic.net/adsf/1672316022276698302A.jpg'></img></h3>
//     </div>
//     <div>
//       <h3 className='carousel2'><img width='100%' className='carimg'  src='https://images.wbstatic.net/bners1/big_smart-2.jpg'></img></h3>
//     </div>
//     <div>
//       <h3 className='carousel2'><img width='100%' className='carimg'  src='https://images.wbstatic.net/bners1/big_tasty_2312.jpg'></img></h3>
//     </div>
//   </Carousel>
//           </div>       
//                 <Mainpost2/>
//                 <div className='d-none d-md-block'>
//                 <Footer/>
//                 </div>
                
                
//                 </div>
//         </div>
//         <div className='rek_right' style={{width:'15%',marginRight:'-50px'}}>
//           <img  style={{marginTop:'200px'}} width='100%' height='280px' src='https://cbu01.alicdn.com/img/ibank/O1CN01ElZaLo1Rlezxm684F_!!933712152-0-cib.310x310.jpg_180x180q90.jpg_.webp'></img>
//         </div>
//     </div>

//     </body>
//     <div  className='d-sm-block d-md-none '>
//       <Footer_menu/>
//     </div>  